import LogoCVWhite from '@/assets/logo-mindpal.svg';

import Icon from '../Icon';
import Text from '../Text';
import NewsletterForm from './NewsletterForm';

const footerMenu = [
  {
    category: 'Company',
    links: [
      { name: 'About us', href: '/about-us' },
      { name: 'hello@mindpal.co', href: 'mailto:hello@mindpal.co' },
      { name: '415 Mission St, San Francisco, CA 94105', href: '#' },
    ],
  },
  {
    category: 'Products',
    links: [
      { name: 'Resume Generator', href: '/resume' },
      { name: 'Job Matching', href: '/job-matching' },
      { name: 'AI HR Assistants', href: 'https://cv.mindpal.co/' },
      { name: 'Pricing', href: 'https://app.mindpal.co/dashboard/pricing' },
    ],
  },
  {
    category: 'Candidates',
    links: [
      { name: 'View offers', href: 'https://app.mindpal.co/offers' },
      { name: 'Log in', href: 'https://app.mindpal.co/auth/login' },
      { name: 'Find your dream job', href: 'https://app.mindpal.co/apply' },
    ],
  },
  {
    category: 'News',
    links: [
      { name: 'Blog cafe', href: 'https://mindpal.co/blog/' },
      { name: 'Newsroom', href: 'https://mindpal.co/newsroom/' },
      { name: 'Resources', href: 'https://mindpal.co/resources/' },
    ],
  },
];

const socials = [
  {
    icon: 'hugeicons:instagram',
    href: 'https://www.instagram.com/mindpal.co/',
  },
  {
    icon: 'ri:facebook-fill',
    href: 'https://www.facebook.com/mindpal.co',
  },
  {
    icon: 'ri:linkedin-fill',
    href: 'https://www.linkedin.com/company/mindpal-co/posts/?feedView=all',
  },
  {
    icon: 'pajamas:twitter',
    href: 'https://x.com/mindpal_co',
  },
];

const policyLinks = [
  { name: 'Privacy Policy', href: 'https://mindpal.co/privacy-policy/' },
  { name: 'Terms of Service', href: 'https://mindpal.co/terms-of-service/' },
];

const Footer = () => {
  return (
    <div className="flex w-full flex-col gap-20 bg-gradient-to-l  from-primary-600 to-secondary-900 p-10 lg:px-56 lg:py-20">
      <NewsletterForm />
      <div className="flex flex-col gap-10 lg:flex-row lg:gap-40">
        <div className="w-40">
          <LogoCVWhite />
        </div>
        <div className="flex flex-1 flex-col justify-end gap-10 lg:flex-row lg:gap-4">
          {footerMenu.map(({ category, links }) => (
            <div key={category} className="flex flex-1 flex-col gap-5">
              <Text variant="body-small" className="font-semibold">
                {category.toLocaleUpperCase()}
              </Text>
              <div className="flex flex-col gap-3">
                {links.map(({ name, href }) => (
                  <a href={href} key={`${category}${name}`}>
                    <Text variant="body-caption">{name}</Text>
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex w-full flex-col gap-10 border-t pt-7 lg:flex-row lg:gap-0">
        <Text variant="body-small" className="flex-1">
          © {new Date().getFullYear().toString()} MindPal. All rights reserved.
        </Text>
        <div className="flex flex-1 flex-row items-center justify-center gap-2">
          {socials.map(({ href, icon }) => (
            <a href={href} key={href} target="_blank">
              <div className="flex aspect-square w-10 items-center justify-center rounded-full border lg:w-14">
                <Icon icon={icon} prefixIconClassName="lg:!text-2xl" />
              </div>
            </a>
          ))}
        </div>
        <div className="flex flex-1 flex-col items-start justify-end gap-10 lg:flex-row">
          {policyLinks.map(({ href, name }) => (
            <a href={href} key={name}>
              <Text variant="body-caption">{name}</Text>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
